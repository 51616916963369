<template lang="pug">
  section.info-text.flex.w-full.justify-start.sm_justify-end
    .w-full.text-left.px-14.sm_pl-0.sm_pr-32.sm_text-left.sm_w-9x12.font-serif.leading-tight
      //- body text
      div(class="text-[1.8rem] sm_text-[2.5vw] max-w-[26em]")
        slot
      //- credits
      div(class="text-[1.3rem] sm_text-[1.6vw]")
        slot(name="credits")
</template>

<script>
export default {
  name: 'InfoText'
}
</script>

<style>
</style>
