<template lang="pug">
main#app.bg-black
  h1.sr-only LAMENT.TV

  logo.absolute.text-black.z-10(rightBtn="info", @rightBtn="openInfo", :class="{'lg_text-white': home && home.data.header_color === 'white'}")

  //- (info)
  article.fixed.z-20.h-screen.w-full.top-0.left-0.transition-all.duration-300.overflow-hidden.flex(v-if="home", :style="{maxHeight: !info ? '0' : '100%'}")
    h2.sr-only Info
    //- scroll body
    .overflow-y-scroll.w-full(ref="infoBody")
      //- card
      .bg-white.text-gray-900.min-h-screen.relative.shadow-md
        //- logo / close btn
        logo.absolute.z-10(@click="closeInfo", rightBtn="close", @rightBtn="closeInfo")
        //- text
        info-text(class="py-80 lg_py-[25vh]")
          prismic-rich-text.children-mt-lh(:field="home.data.info", class="max-w-[18em]")
          div(slot="credits")
            //- credits
            prismic-rich-text.children-mt-lh.mt-56.md_mt-96(:field="home.data.credits")
            //- (sponsors)
            template(v-if="home.data.sponsors")
              .mt-56.md_mt-96
                p(style="font-size:0.75em") <i>Funded by</i>
                ul.flex.flex-wrap
                  //- (sponsor...)
                  li.mr-36.md_mr-72.mt-20.md_mt-32(v-for="item in home.data.sponsors")
                    img.block(:src="item.logo.small.url", :alt="item.logo.alt", :style="{ height: (item.logo_height || 3) + 'em' }")

      //- end scroll area
      .h-screen(@click="closeInfo")
      .h-px(v-intersect, @intersects="closeInfo")

  //- body
  section.transition.duration-500(:class="{'opacity-20': info}")
    //- projects...
    project-card(v-for="(doc, i) in projectCards", :style="{zIndex: projectCards.length - i}", :doc="doc", :key="doc.uid", :index="i")

</template>

<script>
import 'plyr/dist/plyr.css'
import '@/style/index.css'
import Logo from './components/Logo.vue'
import InfoText from './components/InfoText.vue'
import ProjectCard from './components/ProjectCard.vue'
export default {
  name: 'App',
  components: {
    Logo, ProjectCard, InfoText
  },
  data () {
    return {
      docs: [],
      info: false
    }
  },
  computed: {
    home () {
      return this.docs.find(doc => doc.type === 'home')
    },
    projects () {
      return this.docs.filter(doc => doc.type === 'project')
    },
    projectCards () {
      if (this.home) {
        return this.home.data.body.filter(slice => slice.slice_type === 'project_card')
          .map(slice => {
            return this.projects.find(doc => doc.uid === slice.primary.project.uid)
          })
      }
      return []
    }
  },
  methods: {
    async getDocs () {
      this.docs = (await this.$prismic.client.query('')).results
    },
    openInfo () {
      this.info = true
      this.$refs.infoBody.scroll(0, 0)
    },
    closeInfo () {
      this.info = false
    }
  },
  created () {
    this.getDocs()
  }
}
</script>

<style>

</style>
