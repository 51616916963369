<template>
  <svg viewBox="0 0 93 46" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio>
  <path d="M91.1822 23.2128L1.20134 44.8929C0.886808 44.9687 0.584211 44.7303 0.584211 44.4068L0.584213 1.04657C0.584213 0.723042 0.88681 0.484695 1.20133 0.560476L91.1822 22.2406C91.6927 22.3636 91.6927 23.0898 91.1822 23.2128Z" stroke="currentColor" :stroke-width="strokeWidth" />
  </svg>
</template>

<script>
export default {
  name: 'SvgPlayTriangle',
  props: {
    strokeWidth: { type: String, default: '1' }
  }
}
</script>

<style scoped lang="postcss">
@screen lg {
  svg:hover {
    mix-blend-mode:normal !important;
    transform: scale(1.03, 1.03)
  }
  svg{
    transition: all 250ms;
  }
  svg path{
    transition: fill 500ms;
  }
  svg:hover path {
    stroke: white;
    fill:white;
  }
}
</style>
