<template lang="pug">
  article.project-card.bg-white.text-black.shadow-md.left-0.w-full(:class="{'sticky bottom-0': !more, 'relative': more}")
    //-
    header.flex.flex-col.justify-end.overflow-hidden.px-3.relative(:class="{'h-[84.9vh] sm_h-[96vh]': !index, 'h-screen sm_h-screen': index > 0, 'lg_text-white': doc.data.header_text_color === 'white'}")
      //- (video?)
      template(v-if="hasVideo")
        .absolute.top-0.left-0.w-full.h-full.flex.items-center.lg_items-end.lg_bg-black
          video-plyr.w-full(ref="video", :videoLink="doc.data.video")
        //- (lg: play btn)
        button.hidden.lg_flex.flex-1.w-full.items-end.justify-start.relative.p-24(@click="playVideo")
          .mt-32
            svg-play-triangle(style="width:34vh;", :strokeWidth="doc.data.header_text_color === 'white' ? 0.5 : 0.78")

      //- (promo)
      template(v-else)
        //- (promo image)
        .h-32.sm_hidden
        figure.flex-1.relative.cursor-pointer.flex.flex-col.justify-center.items-center(, class="my-[10vh] sm_my-[4vh]", @click.stop="openMore")
          //- (promo image)
          template(v-if="doc.data.promo_image.url")
            img.absolute.top-0.left-0.w-full.h-full.object-contain.object-bottom.sm_object-center(:src="doc.data.promo_image.url")
          template(v-else)
            .relative.w-full(style="flex:0 0 50%")
              img.absolute.top-0.left-0.w-full.h-full.object-contain.object-bottom.sm_object-center(src="~@/assets/frau2y-Kopie-800h-1.gif", v-if="index === 0")
              img.absolute.top-0.left-0.w-full.h-full.object-contain.object-bottom.sm_object-center(src="~@/assets/frau2y-Kopie-800h-2.gif", v-if="index === 1")
              img.absolute.top-0.left-0.w-full.h-full.object-contain.object-bottom.sm_object-center(src="~@/assets/frau2y-Kopie-800h-3.gif", v-if="index === 2")
              img.absolute.top-0.left-0.w-full.h-full.object-contain.object-bottom.sm_object-center(src="~@/assets/frau2y-Kopie-800h-4.gif", v-if="index === 3")

      //- heading
      .font-title.tracking-tight.w-full.uppercase.text-center.md_text-left.pt-12.pb-5.sm_pt-5.cursor-pointer.select-none.relative.md_text-shadow-px(class="text-[4.6rem] sm_text-[9vw] leading-[0.76]", @click="toggleMore", :id="doc.uid")
        //- date / title
        h2.flex.flex-col-reverse.sm_flex-row
          //- date
          div.w-full.sm_w-auto.text-center.relative
            span(class="relative inline-block font-num tracking-tightest")
              | {{ formatDate(doc.data.date) }}
              //- (strike-through)
              .absolute.top-0.left-0.w-full.h-full.flex.items-center(v-if="isPastEvent")
                .w-full.bg-current(class="mt-[0.27em] lg_mt-[0.26em] h-[0.055em] lg_h-[0.05em]", style="flex:1 0 108%")

          div(class="sm_ml-[3vw]")
            | <span v-html="doc.data.artist"></span>

        //- location/time
        prismic-rich-text.px-32.sm_px-0.bold-font-num(v-if="!isPastEvent", :field="doc.data.location")

        //- (more btn)
        button.absolute.bottom-0.right-0.flex.items-center.justify-center.pb-1.sm_pb-0(class="h-[1em] w-[1em]", v-if="hasInfo", @click.stop="toggleMore")
          .border-l.border-b.md_border-l-2.md_border-b-2.border-current.opacity-80.transform.origin-center(class="h-[0.5em] w-[0.5em]", :class="{'rotate-135 translate-y-1x2': more, '-rotate-45': !more}")
          //- <span v-if="prj.street">{{ prj.street }}<span class="font-num tracking-tight" style="font-size:0.97em">{{ prj.number }} &nbsp;{{ prj.hour }}</span>PM</span>

    //- (body expands)
    .pb-24.md_pb-180(v-if="hasInfo", v-show="more")
      //- title
      prismic-rich-text.text-center.sm_text-left.md_text-shadow-px.tracking-tight.mt-48.md_mt-64(class="text-[6rem] sm_text-[9vw] leading-[0.76]", :field="doc.data.title")
      //- subtitle
      prismic-rich-text.uppercase.text-center.sm_text-left.xl_text-shadow-pxff.tracking-tight.mt-8(class="text-[2.6rem] sm_text-[6vw] leading-[0.76]", :field="doc.data.subtitle")

      //- info text
      info-text.mt-56.md_mt-72
        prismic-rich-text.children-mt-lh(:field="doc.data.info")
        prismic-rich-text.children-mt-lh.mt-72.md_mt-120(slot="credits", :field="doc.data.credits")
</template>

<script>
import InfoText from '@/components/InfoText'
import VideoPlyr from '@/components/VideoPlyr'
import SvgPlayTriangle from '@/components/SvgPlayTriangle'
export default {
  name: 'ProjectCard',
  props: ['doc', 'index'],
  components: { InfoText, VideoPlyr, SvgPlayTriangle },
  data () {
    return {
      more: false
    }
  },
  computed: {
    hasInfo () {
      return this.$prismic.asText(this.doc.data.info).length
    },
    hasVideo () {
      return this.doc.data.video.url
    },
    isPastEvent () {
      return new Date().getTime() > new Date(this.doc.data.date).getTime() + (24 * 60 * 60 * 1000)
    }
  },
  methods: {
    formatDate (dateString) {
      try {
        const date = new Date(dateString)
        return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
      } catch (e) {
        console.error(e)
        return null
      }
    },
    toggleMore () {
      if (this.more) {
        this.more = false
      } else {
        this.openMore()
      }
    },
    openMore () {
      this.more = true
      this.$scrollTo('#' + this.doc.uid)
    },
    playVideo () {
      this.$refs.video.playFull()
    }
  }
}
</script>

<style>
</style>
