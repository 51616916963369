import Vue from 'vue'
import App from './App'
import PrismicVue from '@prismicio/vue'
import linkResolver from '@/plugins/prismic/link-resolver'
import VueScrollTo from 'vue-scrollto'

// Register Prismic
Vue.use(PrismicVue, {
  endpoint: `https://${process.env.VUE_APP_PRISMIC_REPO}.cdn.prismic.io/api/v2`,
  // apiOptions: { accessToken },
  linkResolver
})

Vue.use(VueScrollTo, { duration: 800 })

//
// Global directive to observe element visibility
// <div v-intersect="0.1" @visible, @hidden>
Vue.directive('intersect', {
  inserted: function (el, binding, vnode) {
    const threshold = binding.value || 0.5 // v-intersect="0.1"
    const onIntersection = entries => {
      const eventName = entries[0].isIntersecting ? 'intersects' : 'outersects'
      // emit...
      if (vnode.componentInstance) {
        // component ?
        vnode.componentInstance.$emit(eventName) // , {detail: eventDetail}); // use {detail:} to be uniform
      } else {
        // vanilla DOM element
        vnode.elm.dispatchEvent(new CustomEvent(eventName)) // , {detail: eventDetail}));
      }
    }
    // observe!
    el.observer = new IntersectionObserver(onIntersection, { threshold })
    el.observer.observe(el)
  }
  // unbind: function (el) {
  //   el.observer.unobserve(el)
  // }
})

new Vue({
  render: (h) => h(App)
}).$mount('#app')
