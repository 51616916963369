<template lang="pug">
  .video-plyr.relative.transition-opacity.duration-200(:class="{'lg_opacity-0ff': !loaded}")
    template(v-if="videoLink.link_type === 'Web'")
      div(ref="video", :data-plyr-provider="embedProvider", :data-plyr-embed-id="embedID")

    //- template(v-else-if="videoLink.link_type === 'Media'")

    //- (mobile play btn)
    button.lg_hidden.absolute.top-0.left-0.w-full.h-full.flex.items-center.justify-center(v-show="previewMode", @click="playFull")
      svg-play-triangle.text-black(style="height:14vw; mix-blend-mode:differenceff", strokeWidth="1.25")

    //- (loading icon)
    //- .hidden.absolute.top-0.left-0.h-full.w-full.items-center.justify-center.z-20(:class="{'lg_flex': !autoPlayed}")
      .video-plyr__loading-icon.border-l.border-white.lg_border-white.h-48
</template>

<script>
import SvgPlayTriangle from '@/components/SvgPlayTriangle'
import Plyr from 'plyr'
export default {
  name: 'VideoPlyr',
  props: ['videoLink'],
  components: { SvgPlayTriangle },
  data () {
    return {
      loaded: false,
      // autoPlayed: false,
      player: null,
      previewMode: true,
      previewDuration: 10
    }
  },
  computed: {
    embedProvider () {
      try {
        const url = new URL(this.videoLink.url)
        // get provider from second to last part of host (www.youtube.com, vimeo.com, ...)
        let provider = url.host.split('.').slice(-2, 1)[0]
        // catch youtu.be urls...
        if (provider.startsWith('youtu')) {
          provider = 'youtube'
        }
        return provider
      } catch (e) {
        console.error('Error parsing provider:' + e)
        return false
      }
    },
    embedID () {
      try {
        const url = new URL(this.videoLink.url)
        return url.pathname.split('/').pop()
      } catch (e) {
        console.error('Error parsing embedID:' + e)
        return false
      }
    }
  },
  methods: {
    init () {
      this.player = new Plyr(this.$refs.video, {
        // debug: true,
        autoplay: true,
        // volume: 0,
        autopause: false,
        // loop: { active: true },
        muted: true,
        storage: { enabled: false }, // disable otherwise doesn't unpause...

        // controls
        hideControls: true,
        controls: ['play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen'], // ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'settings', 'pip', 'airplay', 'fullscreen']

        // fullscreen (iOS enabled breaks previewMode...)
        fullscreen: { enabled: true, fallback: true, iosNative: true, container: null }
      })
      // force hide controls...
      this.player.on('ready', () => {
        this.player.toggleControls(false)
        setTimeout(() => this.player.toggleControls(false), 800)
      })
      // events
      this.player.on('exitfullscreen', this.onFullscreenExit)
      this.player.on('timeupdate', this.onTimeupdate)
      this.player.on('ended', this.onEnded)
      // loading timeout (low-power mode)
      // setTimeout(() => { this.loaded = true }, 3000)
    },
    playFull () {
      this.previewMode = false
      this.player.restart()
      this.player.fullscreen.enter()
      this.player.play()
      this.player.muted = false
    },
    enterPreviewMode () {
      this.previewMode = true
      this.player.restart()
      this.player.muted = true
      this.player.play()
    },
    onTimeupdate (e) {
      // // end loading once playing
      // if (this.player.currentTime > 0 && !this.autoPlayed) {
      //   this.autoPlayed = true
      //   // TODO - handle loading error since hidden...
      // }

      // short loop in preview mode
      if (this.previewMode) {
        if (this.player.currentTime >= this.previewDuration) {
          this.player.currentTime = 2 // in case black screen starting
        }
      }
    },
    onFullscreenExit () {
      this.enterPreviewMode()
    },
    onEnded () {
      this.player.fullscreen.exit()
      this.enterPreviewMode()
    }
  },
  mounted () {
    this.init()
  }
}
</script>

<style>
.video-plyr__loading-icon{
  /*border-style: dashed;*/
  animation: spin 2s infinite linear;
  transform-origin: center center;
}
@keyframes spin {
  0%{ transform: rotate(0deg); }
  100% { transform: rotate(-360deg); }
}
/*0%{ transform: rotate(93deg) }
50%{ transform: rotate(-93deg); }
100% { transform: rotate(93deg) }*/

</style>
